import { useState, useEffect } from 'react'
import { Button, Upload, Form, Checkbox, Col, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const UploadField = props => {
  const { index, dataIndex, validation, form } = props
  const [documentUploaded, setDocumentUploaded] = useState(false)

  useEffect(() => {
    const copiedForm = form.getFieldValue()
    copiedForm.orders[index].alterations !== '' && setDocumentUploaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  const removeDocumentUpload = () => {
    const copiedForm = form.getFieldValue()
    copiedForm.orders[index].alterations = ''
    form.setFieldValue(copiedForm)

    setDocumentUploaded(false)
  }

  const handleUploadImage = async ({ onSuccess }) => {
    onSuccess('ok')
  }

  const uploadOnChange = async info => {
    const isLt2M = info.file.size / 1024 / 1024 < 5
    const newForm = form.getFieldValue()

    if (!isLt2M) {
      message.error('Image must smaller than 5MB!')
      setDocumentUploaded(false)
    } else {
      // await uploadImage(info.file.originFileObj)
      const file = info.file
      newForm.orders[index].alterations = file
      form.setFieldValue(newForm)
      setDocumentUploaded(true)
    }
  }

  return (
    <Form.Item
      wrapperCol={{ span: 0 }}
      name={[index, dataIndex]}
      rules={validation}
      style={{ padding: '24px 10px 0 10px', width: '100%' }}
    >
      {!documentUploaded ? (
        <Upload
          maxCount={1}
          showUploadList={false}
          customRequest={handleUploadImage}
          onChange={uploadOnChange}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      ) : (
        <Col span={24} align='center'>
          <Checkbox
            onChange={() => removeDocumentUpload()}
            defaultChecked
          ></Checkbox>
        </Col>
      )}
    </Form.Item>
  )
}

export default UploadField
