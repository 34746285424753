import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Table, Row } from 'antd'
import TableItem from './TableItem'

import {
  productCodeValidation,
  sizeValidation,
  colourValidation,
  quantityValidation
} from '../util/validation'

const FormTable = props => {
  const { orders, add, form, remove } = props

  const addOrder = () => {
    add({
      productCode: '',
      size: '',
      colour: '',
      fabricCode: '',
      quantity: 1,
      alterations: ''
    })
  }

  // const sizeRender = () => {
  //   return (_, record, index) => {
  //     let setValidation
  //     if (form.getFieldsValue().orders !== undefined) {
  //       const thirdChar = form
  //         .getFieldsValue()
  //         .orders[index].productCode.split('')[2]
  //       if (thirdChar === 'A' || thirdChar === 'a') {
  //         setValidation = false
  //       } else {
  //         setValidation = true
  //       }
  //     } else {
  //       setValidation = true
  //     }

  //     return (
  //       <TableItem
  //         record={record}
  //         index={index}
  //         dataIndex={'size'}
  //         validation={setValidation ? sizeValidation : [{ required: false }]}
  //         form={form}
  //       ></TableItem>
  //     )
  //   }
  // }

  const TableTitle = props => {
    const { label, required = false } = props

    return (
      <div className='tableTitle'>
        {`${label} `} {required ? <span className='tableAstrisk'>*</span> : ''}
      </div>
    )
  }

  const columns = [
    {
      title: <TableTitle label='Product Code' required />,
      dataIndex: 'productCode',
      key: 'productCode',
      width: '25%',
      render: (_, record, index) => (
        <TableItem
          record={record}
          index={index}
          dataIndex={'productCode'}
          validation={productCodeValidation}
          form={form}
        ></TableItem>
      )
    },
    {
      title: <TableTitle label='Size' required />,
      dataIndex: 'size',
      key: 'size',
      width: '15%',
      render: (_, record, index) => (
        <TableItem
          record={record}
          index={index}
          dataIndex={'size'}
          validation={sizeValidation}
          form={form}
        ></TableItem>
      )
    },
    {
      title: <TableTitle label='Colour' required />,
      dataIndex: 'colour',
      key: 'colour',
      width: '15%',
      render: (_, record, index) => (
        <TableItem
          record={record}
          index={index}
          dataIndex={'colour'}
          validation={colourValidation}
          form={form}
        ></TableItem>
      )
    },
    {
      title: <TableTitle label='Fabric Code' />,
      dataIndex: 'fabricCode',
      key: 'fabricCode',
      width: '25%',
      render: (_, record, index) => (
        <TableItem
          record={record}
          index={index}
          dataIndex={'fabricCode'}
          form={form}
        ></TableItem>
      )
    },
    {
      title: <TableTitle label='Qty' required />,
      dataIndex: 'quantity',
      key: 'quantity',
      width: '5%',
      render: (_, record, index) => (
        <TableItem
          record={record}
          index={index}
          dataIndex={'quantity'}
          validation={quantityValidation}
          form={form}
        ></TableItem>
      )
    },
    {
      title: <TableTitle label='Alterations' />,
      dataIndex: 'alterations',
      key: 'alterations',
      width: '5%',
      render: (_, record, index) => (
        <TableItem
          record={record}
          index={index}
          dataIndex={'alterations'}
          form={form}
        ></TableItem>
      )
    },
    {
      title: '',
      key: 'delete',
      width: '5%',
      render: (_, record) => (
        <Button
          style={{ margin: '10px' }}
          onClick={() => remove(record.name)}
          icon={<DeleteOutlined />}
          danger
        />
      )
    }
  ]

  return (
    <>
      <Table
        columns={columns}
        bordered={true}
        dataSource={orders}
        pagination={false}
        className='formTable'
      ></Table>

      <Row style={{ margin: '20px 0' }}>
        <Button onClick={() => addOrder()} icon={<PlusOutlined />}>
          Add another row
        </Button>
      </Row>
    </>
  )
}

export default FormTable
