import React from 'react'
import { Row, Col, Typography, Button, Card } from 'antd'

const { Title } = Typography

const Home = () => {
  return (
    <div style={{ height: '100%' }}>
      <Row
        justify='center'
        align='middle'
        style={{ height: '100%', margin: '2rem 0' }}
      >
        <Card>
          <Col span={24} align='center'>
            <Title level={4}>
              If you can see this page, you are logged in successfully.
            </Title>
          </Col>
          <Col span={24} align='center'>
            <Button>
              <a href='/'>Back To Form</a>
            </Button>
            <Button>
              <a href='/.auth/logout'>Log out</a>
            </Button>
          </Col>
        </Card>
      </Row>
    </div>
  )
}

export default Home
