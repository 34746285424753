import './App.css'
import { Route, Routes } from 'react-router-dom'

import Login from './Login'
import Home from './Home'
import FormContainer from './components/FormContainer'

function App () {
  return (
    <>
      <Routes>
        <Route path='/' element={<FormContainer />} />
        <Route path='/login' element={<Login />} />
        <Route path='/home' element={<Home />} />
      </Routes>
    </>
  )
}

export default App
