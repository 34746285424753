import { useState } from 'react'
import { Input, Form, Col, InputNumber } from 'antd'
import UploadField from '../form-input-types/UploadField'

const TableItem = ({ dataIndex, index, validation, form }) => {
  const { orders } = form.getFieldsValue()
  const [productCode, setProductCode] = useState('')

  const handleProductInput = e => {
    setProductCode(e.target.value.toUpperCase())
    if (orders !== undefined) {
      form.setFieldsValue({})
    }
  }

  switch (dataIndex) {
    case 'quantity':
      return (
        <Form.Item
          wrapperCol={{ span: 0 }}
          name={[index, dataIndex]}
          rules={validation}
          style={{ padding: '24px 10px 0 10px', width: '80px' }}
        >
          <InputNumber min={1} max={10} style={{ width: '100%' }} />
        </Form.Item>
      )
    case 'alterations':
      return (
        <Col align='center'>
          <UploadField
            index={index}
            dataIndex={dataIndex}
            validation={validation}
            form={form}
          />
        </Col>
      )
    case 'productCode':
      return (
        <Form.Item
          wrapperCol={{ span: 0 }}
          name={[index, dataIndex]}
          rules={validation}
          style={{ padding: '24px 10px 0 10px', width: '100%' }}
        >
          <Input
            type='text'
            value={productCode}
            onChange={e => handleProductInput(e)}
            style={{ textTransform: 'uppercase' }}
            autoComplete='off'
          />
        </Form.Item>
      )
    case 'fabricCode':
      return (
        <Form.Item
          wrapperCol={{ span: 0 }}
          name={[index, dataIndex]}
          rules={validation}
          style={{ padding: '24px 10px 0 10px', width: '100%' }}
        >
          <Input autoComplete='off' />
        </Form.Item>
      )
    default:
      return (
        <Form.Item
          wrapperCol={{ span: 0 }}
          name={[index, dataIndex]}
          rules={validation}
          style={{ padding: '24px 10px 0 10px', width: '100%' }}
        >
          <Input autoComplete='off' />
        </Form.Item>
      )
  }
}

export default TableItem
