import React, { useState, useEffect } from 'react'
import { Button, Form, Divider, Typography } from 'antd'
import FormField from './FormField'
import FormTable from './form-table/FormTable'

import {
  nameValidation,
  emailValidation,
  requestStoreValidation,
  deliveredToStoreValidation,
  staffNameValidation,
  defaultValidation
} from './util/validation'
import stores from './assets/stores.json'

const { Title, Text } = Typography

const WebForm = props => {
  const { setFormState, formState } = props
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(formState.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.submitted])

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 12 },
      md: { span: 12 }
    },
    wrapperCol: {
      xs: { span: 0 },
      sm: { span: 12 },
      md: { span: 12 }
    }
  }

  const onFinish = values => {
    values.orders.map(order => {
      if (order.size === 'na') order.size = 'NA'
      return (order.productCode = order.productCode.toUpperCase())
    })

    console.log('Received values of form: ', values)
    setFormState(state => ({
      ...state,
      submitted: true,
      submitting: false,
      values
    }))
  }

  const [deliveryValue, setDeliveryValue] = useState(
    formState.values.delivery !== ''
      ? formState.values.delivery
      : 'The customer'
  )
  const deliveryOptions = [
    'The requesting store',
    'The customer',
    'Another store'
  ]

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name='register'
        onFinish={onFinish}
        initialValues={formState.values}
        scrollToFirstError
      >
        <FormField
          name='customerName'
          label='Customer Name'
          validation={nameValidation}
        />

        <FormField
          name='customerEmail'
          label='Customer Email'
          validation={emailValidation}
        />

        <FormField
          name='phone'
          prefixName='phonePrefix'
          phonePrefix={formState.values.phonePrefix}
          numberName='phoneNumber'
          label='Customer Phone'
          inputType='phone'
        />

        <FormField name='customerRef' label="Customer's Reference" />

        <FormField
          name='delivery'
          label='Where will this order be delivered?'
          inputType='radio'
          radioValue={deliveryValue}
          setRadioValue={setDeliveryValue}
          values={deliveryOptions}
        />

        {deliveryValue === 'The customer' && (
          <>
            <FormField
              name='address'
              label='Customer Delivery Address'
              inputType='address'
              validation={defaultValidation}
            />
          </>
        )}
        {deliveryValue === 'Another store' && (
          <>
            <FormField
              name='storeToDeliver'
              label='Deliver to Store'
              inputType='dropdown'
              validation={deliveredToStoreValidation}
              values={stores}
            />
          </>
        )}

        <Divider></Divider>

        <Title level={4}>Product Order Info</Title>
        <Text>
          An alterations form must be uploaded for any order requiring
          alterations.
        </Text>

        <Form.List name='orders'>
          {(orders, { add, remove }) => {
            return (
              <>
                <FormTable
                  form={form}
                  orders={orders}
                  add={add}
                  remove={remove}
                ></FormTable>
              </>
            )
          }}
        </Form.List>

        <FormField
          name='requestingStore'
          label='Requested by Store'
          inputType='dropdown'
          validation={requestStoreValidation}
          values={stores}
        />

        <FormField
          name='staffName'
          label='Staff Members Name'
          validation={staffNameValidation}
        />

        <Form.Item wrapperCol={{ span: 4, offset: 10 }}>
          <Button type='primary' htmlType='submit'>
            Proceed to review
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default WebForm
