import { useState } from 'react'
import { Card, Row, Col, Typography, Divider, Spin, Modal } from 'antd'
import WebForm from './WebForm'
import FormReview from './FormReview'
import Acceptance from './Acceptance'

import { initialValues } from './assets/initialValues'

import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

const { Title, Text } = Typography

const initialFormState = {
  submitted: false,
  submitting: false,
  posted: false,
  postingFailed: false,
  uploadFailed: false,
  values: initialValues
}

const FormHeader = props => {
  const { formState } = props

  let text =
    'Please complete the form below to order products with or without alterations.'

  if (formState.submitted) {
    text = 'Please review the details below before submitting.'
  }

  return (
    <Row>
      {process.env.REACT_APP_ENV === 'development' && (
        <Col
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            border: '1px solid #FAAD14',
            borderRadius: '8px 0 0 0',
            padding: '6px 24px',
            backgroundColor: '#FAAD14',
            color: 'white'
          }}
        >
          Development
        </Col>
      )}
      <Col span={24} align='center'>
        <Title level={2}>Cambridge Clothing Order Form</Title>
      </Col>
      {!formState.posted && (
        <>
          <Col span={24} align='center'>
            <Text>{text}</Text>
          </Col>
          <Divider></Divider>
        </>
      )}
    </Row>
  )
}

const FormBody = props => {
  const { formState, setFormState, setUploadProgress } = props
  const id = uuidv4()

  const handleReset = () => setFormState(initialFormState)
  const handlePost = async () => {
    setFormState(state => ({
      ...state,
      submitting: true
    }))

    const newForm = formState.values

    const files = []

    newForm.orders.map(order => {
      if (order.alterations !== '') {
        files.push(order.alterations)
        order.alterations = order.alterations.uid
      }
      return 0
    })

    const handleFileUpload = async file => {
      try {
        const url =
          process.env.REACT_APP_ENV === 'development'
            ? `${process.env.REACT_APP_BACKEND_DEV_URL}/api/upload-file`
            : `${process.env.REACT_APP_BACKEND_URL}/api/upload-file`
        const formData = new FormData()
        formData.append(file.uid, file.originFileObj)
        formData.append('id', id)

        await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-functions-key': process.env.REACT_APP_BACKEND_KEY
          },
          onUploadProgress: progressEvent => {
            const progress = progressEvent.progress === 1 ? true : false
            setUploadProgress(prevProgress => [
              ...prevProgress.filter(item => item.id !== file.id),
              { id: file.uid, uploadStatus: progress }
            ])
          }
        })

        return true
      } catch (error) {
        console.log(error)
        setFormState(state => ({
          ...state,
          posted: true,
          postingFailed: true,
          uploadFailed: true,
          submitting: false,
          values: newForm
        }))
        return false
      }
    }

    const submitUrl =
      process.env.REACT_APP_ENV === 'development'
        ? `${process.env.REACT_APP_REQUEST_DEV_URL}/api/submit-request`
        : `${process.env.REACT_APP_REQUEST_URL}/api/submit-request`

    try {
      const uploadPromises = Array.from(files).map(file =>
        handleFileUpload(file)
      )

      let fileUploaded = false

      await Promise.all(uploadPromises).then(result => {
        fileUploaded = result.every(x => x === true) ? true : false
      })

      // Make the form submission request
      if (fileUploaded || files.length === 0) {
        try {
          await axios.post(
            submitUrl,
            {
              data: formState.values,
              id: id
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )

          setFormState(state => ({
            ...state,
            posted: true,
            submitting: false,
            values: formState.values
          }))
        } catch (e) {
          console.log(e)
          setFormState(state => ({
            ...state,
            posted: true,
            postingFailed: true,
            submitting: false,
            values: formState.values
          }))
        }
      }
    } catch (error) {
      console.log(error)
      setFormState(state => ({
        ...state,
        posted: true,
        postingFailed: true,
        submitting: false,
        values: newForm
      }))
    }

    setUploadProgress([])
  }

  const handleEdit = () => {
    setFormState(state => ({
      ...state,
      submitted: false
    }))
  }

  const handlePostFailed = () => {
    const copiedForm = formState.values

    copiedForm.orders.map(order => (order.alterations = ''))

    const warning = () => {
      Modal.warning({
        title: 'Alteration file has been removed.',
        content:
          'Please upload an alteration file to the corresponding order again.'
      })
    }

    setFormState(state => ({
      ...state,
      posted: false,
      postingFailed: false,
      uploadFailed: false,
      submitted: false,
      values: copiedForm
    }))

    warning()
  }

  return !formState.submitted ? (
    <WebForm {...{ formState, setFormState, handleReset }} />
  ) : !formState.posted ? (
    <FormReview
      {...{
        formState,
        setFormState,
        handlePost,
        handleEdit
      }}
    />
  ) : (
    <Acceptance
      {...{
        formState,
        handleReset,
        handlePostFailed
      }}
    ></Acceptance>
  )
}

const FormContainer = props => {
  const [formState, setFormState] = useState(initialFormState)
  const [uploadProgress, setUploadProgress] = useState([])

  const LoadingTip = () => {
    return (
      <div>
        <div>Submitting...</div>
        <div>{`${
          uploadProgress.filter(x => x.uploadStatus === true).length
        } of ${
          formState.values.orders.filter(order => order.alterations !== '')
            .length
        } file uploaded`}</div>
      </div>
    )
  }

  return (
    <div className='App' style={{ height: '100%' }}>
      <Row
        justify='center'
        align='middle'
        style={{ height: '100%', margin: '2rem 0' }}
      >
        <Col sm={22} md={20} lg={18} xl={12}>
          <Spin
            tip={<LoadingTip></LoadingTip>}
            size='large'
            spinning={formState.submitting}
          >
            <Card>
              <FormHeader formState={formState}></FormHeader>
              <FormBody
                formState={formState}
                setFormState={setFormState}
                setUploadProgress={setUploadProgress}
              ></FormBody>
            </Card>
          </Spin>
        </Col>
      </Row>
    </div>
  )
}

export default FormContainer
