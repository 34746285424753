export const defaultValidation = [
  { required: true, message: 'Please enter a required field' }
]

export const emailValidation = [
  { required: true, message: '' },
  {
    message: 'Please enter a valid email address',
    validator: (_, value) => {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          value
        )
      ) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    }
  }
]

export const phonePrefixValidation = [
  { required: true, message: 'Please enter a prefix' },
  {
    message: 'Please use numbers for prefix',
    validator: (_, value) => {
      if (value.length === 0) return Promise.resolve()
      if (/^[+0-9]/.test(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    }
  }
]

export const phoneNumberValidation = [
  { required: true, message: 'Please enter a phone number' },
  {
    message: 'Please use numbers for phone number',
    validator: (_, value) => {
      if (value.length === 0) return Promise.resolve()
      if (value.length >= 13) return Promise.resolve()

      if (/^([0-9]|[0-9][0-9- ]+)$/.test(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    }
  },
  {
    message: 'Phone number is too long',
    validator: (_, value) => {
      if (value.length <= 13) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    }
  }
]

export const orderNumberValidation = [
  { required: true, message: 'Please enter a order number' },
  {
    message: 'Customer Order Number cannot be longer than 30 characters',
    validator: (_, value) => {
      if (value.length <= 30) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    }
  }
]

export const nameValidation = [
  { required: true, message: 'Please enter a name' }
]

export const storeValidation = [
  { required: true, message: 'Please select requested store' }
]

export const productCodeValidation = [
  { required: true, message: 'Please enter product code e.g. PGHC000030' },
  {
    message: "Code must start with 'P'",
    validator: (_, value) => {
      if (value.length === 0) return Promise.resolve()
      if (/^P/.test(value) || /^p/.test(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    }
  },
  {
    message: 'Code must be longer than 10 characters',
    validator: (_, value) => {
      if (value.length === 0) return Promise.resolve()
      if (value.length >= 10) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    }
  },
  {
    message: 'Please remove special character',
    validator: (_, value) => {
      if (value.length === 0) return Promise.resolve()
      if (!/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    }
  }
]

export const sizeValidation = [
  { required: true, message: "Enter 'NA' if no size" }
]

export const styleValidation = [
  { required: true, message: 'Please enter style' }
]

export const fabricCodeValidation = [
  { required: true, message: 'Please enter fabric code' }
]

export const colourValidation = [
  { required: true, message: 'Please enter colour' }
]

export const quantityValidation = [
  { required: true, message: 'Please enter quantity' }
]

export const requestStoreValidation = [
  { required: true, message: 'Please select requesting store' }
]

export const deliveredToStoreValidation = [
  { required: true, message: 'Please select delivered to store' }
]

export const staffNameValidation = [
  { required: true, message: 'Please enter staff member name' }
]
