import React from 'react'
import { Form, Input } from 'antd'

const FormInput = props => {
  const { name, label, labelAlign = 'left', validation } = props

  return (
    <Form.Item
      name={name}
      label={label}
      labelAlign={labelAlign}
      rules={validation}
    >
      <Input />
    </Form.Item>
  )
}

export default FormInput
