import React from 'react'
import { Form, Space, Radio } from 'antd'

const RadioInput = props => {
  const {
    name,
    label,
    labelAlign = 'left',
    radioValue,
    setRadioValue,
    values
  } = props
  const radioOnChange = e => {
    setRadioValue(e.target.value)
  }

  return (
    <Form.Item name={name} label={label} labelAlign={labelAlign}>
      <Radio.Group onChange={e => radioOnChange(e)} value={radioValue}>
        <Space direction='vertical'>
          {values.map((value, index) => {
            return (
              <Radio key={index} value={value}>
                {value}
              </Radio>
            )
          })}
        </Space>
      </Radio.Group>
    </Form.Item>
  )
}

export default RadioInput
